/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: "Montserrat", fallback, sans-serif;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header and Banner Section */

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

header {
  background: #fff;
  padding: 10px 0;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo-container {
  flex-basis: 20%;
}

.logo {
  display: inline-block;
  margin-left: 20px;
  font-size: 1.5em;
}

nav ul,
.social-media {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

nav ul li,
.social-media a {
  display: inline-block;
}

.nav-bar {
  flex-basis: 60%;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list li {
  padding: 0 15px;
}

.nav-list a {
  text-decoration: none;
  color: #514e4e;
  font-weight: bold;
  font-size: 1.2em;
}

.social-media {
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;
}

.icon-link img {
  margin-left: 10px;
}

/* Responsive Design */
.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #333;
}

.banner {
  background: url("images/banner_01.webp") no-repeat center center/cover;
  color: #fff;
  padding: 50px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}

.banner h2 {
  font-size: 3em;
  margin-bottom: 20px;
}

.banner p {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  background: #0066cc;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.service-button:hover,
.cta-button:hover {
  background: #0057a3;
}

@media (max-width: 768px) {
  .header-container {
    padding: 0;
  }

  .nav-list {
    position: absolute;
    left: 0;
    opacity: 0;
    top: -300%;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #ccc;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .nav-list.active {
    top: 45px;
    opacity: 1;
    z-index: 1;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .nav-list li {
    padding: 15px 0;
    width: 100%;
    text-align: center;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .social-media {
    flex-basis: 100%;
    justify-content: center;
    padding-top: 10px;
  }

  .logo-container,
  .social-media {
    flex-basis: 100%;
    text-align: center;
  }

  .banner {
    height: 50vh;
  }

  .banner h2 {
    font-size: 1.5em;
  }

  .banner p {
    font-size: 0.9em;
  }

  .cta-button {
    padding: 8px 20px;
  }
}

.nav-list li a {
  padding: 15px 0;
  color: #514e4e;
  text-decoration: none;
  position: relative;
  transition: color 0.3s, border-bottom 0.3s;
}

/* Hover state: change text color + animate underline */
.nav-list li a:hover {
  color: #0073e6;
}

.nav-list li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #0073e6;
  transition: width 0.3s ease-in-out;
}

.nav-list li a:hover::after,
.nav-list li a.active::after {
  width: 100%;
}

/* Active state: add the active class to the current page link in your HTML */
.nav-list li a.active {
  color: #0073e6;
}

/* Media Queries for Mobile */
@media (max-width: 480px) {
  .container {
    width: 95%;
  }
  .logo {
    font-size: 1.2em;
  }

  .banner h2 {
    font-size: 2em;
  }

  .banner p {
    font-size: 1em;
  }

  .cta-button {
    padding: 6px 18px;
    font-size: 0.8em;
  }

  .header-container {
    flex-direction: column;
  }

  .nav-bar {
    order: 3;
    width: 100%;
    text-align: center;
  }

  .logo-container {
    order: 1;
  }

  .social-media {
    order: 2;
    margin-bottom: 10px;
  }
}

.section {
  background-color: #0056b3;
  color: white;
  padding: 50px;
  text-align: center;
}
.section-title {
  font-size: 3em;
  margin-bottom: 10px;
}
.title-underline {
  width: 100px;
  height: 4px;
  background-color: gray;
  margin: 0 auto 30px;
}
.section-text {
  font-size: 24px;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .section-title {
    font-size: 2em;
  }
  .about-text {
    font-size: 1.2em;
  }
}

/* Services Section */

.services-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #fff;
}
.section-title.light {
  color: #0c4da2;
}
.service-card {
  margin-bottom: 30px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.service-image {
  width: 100%;
  height: auto;
  display: block;
}
.service-content {
  padding: 20px;
}
.service-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
  text-align: left;
}
.service-text {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  text-align: left;
}
.service-content ul.service-text {
  padding: 0 20px;
  margin: 0;
}
.service-button {
  display: inline-block;
  width: 90%;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #0056b3;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
}
@media (min-width: 768px) {
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .service-card {
    margin-bottom: 0;
  }
}

/* Transforma section */
.transform-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0 0;
  background-color: #0056b3;
}

.transform-content {
  background-color: #0056b3;
  color: white;
  padding: 20px;
  width: 100%;
}

.transform-title {
  font-size: 2em;
  margin: 0;
}

.transform-subtitle {
  font-size: 24px;
  margin: 10px 0 20px;
}

.transform-image {
  width: 100%;
  height: auto;
  display: block;
}

/* testimonials */
.testimonials-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.testimonials-title {
  color: #0056b3;
}

.testimonials-underline {
  width: 100px;
  height: 4px;
  background-color: #0056b3;
  margin: 10px auto 20px;
}

.testimonials-subtitle {
  font-size: 32px;
  color: #333;
  margin-bottom: 30px;
}

.testimonial {
  position: relative;
  font-size: 20px;
  color: #666;
  padding: 20px;
}

.testimonial-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  opacity: 0;
}

.testimonial-slide.active {
  visibility: visible;
  opacity: 1;
}

.testimonial-text {
  margin-bottom: 30px;
}

.testimonial-indicators {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
}

.indicator {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  margin: 0 5px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.indicator.active {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .testimonials-title {
    font-size: 2em;
  }
  .testimonials-subtitle {
    font-size: 1.5em;
  }
  .testimonial-text {
    font-size: 18px;
  }
}

.contact-section {
  background: url(images/banner_01.webp) no-repeat center center/cover;
  color: white;
}

.contact-wrapper {
  padding: 40px 20px;
  background-color: rgba(0, 86, 179, 0.8);
  text-align: center;
}
.contact-title {
  font-size: 36px;
  margin-bottom: 10px;
}

.contact-subtitle {
  font-size: 28px;
  margin-bottom: 30px;
}

.contact-methods {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.contact-method {
  display: flex;
  align-items: center;
  background-color: white;
  color: #0056b3;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  border: 2px solid transparent;
}

.contact-method.email {
  margin-right: 10px;
}

.contact-method.whatsapp {
  background-color: #25d366;
  color: white;
}

.contact-method i {
  margin-right: 10px;
}

.contact-method:hover {
  background-color: #004a9d;
  color: white;
  border: 2px solid white;
}

.contact-method.whatsapp:hover {
  background-color: #20b358;
  border: 2px solid transparent;
}
@media (max-width: 768px) {
  .contact-title {
    font-size: 28px;
  }

  .contact-subtitle {
    font-size: 22px;
  }

  .contact-method {
    margin: 10px;
    font-size: 16px;
    padding: 8px 16px;
  }

  .contact-methods {
    flex-direction: column;
  }
}

.fa-whatsapp {
  background: url("images/whatsapp.svg") no-repeat center center/cover;
  width: 20px;
  height: 20px;
}

.fa-envelope {
  background: url("images/email.svg") no-repeat center center/cover;
  width: 20px;
  height: 20px;
}

.contact-method.email:hover .fa-envelope {
  background: url("images/email_hover.svg") no-repeat center center/cover;
}

.footer-section {
  background-color: #333;
  color: white;
  padding: 40px 40px 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto 40px;
}

.footer-logo {
  max-width: 100%;
  height: auto;
}

.footer-services h4,
.footer-contact h4 {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 15px;
  line-height: 25px;
  border-bottom: 2px solid #00aaff;
}

.footer-services ul,
.footer-contact ul {
  list-style-type: none;
  padding: 0;
}

.footer-services li,
.footer-contact li {
  margin-bottom: 10px;
}

.footer-contact a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #0088cc;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-branding,
  .footer-services,
  .footer-contact {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .footer-contact a {
    display: block;
  }
}
.fa-phone {
  display: inline-block;
  background: url("images/phone.svg") no-repeat center center/cover;
  width: 16px;
  height: 16px;
}

.footer-bottom {
  color: #fff;
  padding: 10px 0;
  text-align: center;
  border-top: 2px solid #00aaff;
  width: 80%;
  margin: 0 auto;
}

.footer-developed-by {
  text-align: center;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.footer-developed-by p {
  margin: 0 0 5px 0;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-developed-by a {
  text-decoration: none;
  color: #0073e6;
}

.footer-developed-by img {
  max-height: 50px;
  display: inline-block;
}

